import React from "react"

const FeaturesContext = React.createContext()

const FeaturesContextProvider = ({ children }) => {
  const [features, setFeatures] = React.useState([])

  return (
    <FeaturesContext.Provider value={[features, setFeatures]}>
      {children}
    </FeaturesContext.Provider>
  )
}

const useFeatures = () => React.useContext(FeaturesContext)

export { useFeatures, FeaturesContext, FeaturesContextProvider }
