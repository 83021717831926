import React from "react"
import { Grommet } from "grommet"
import theme from "./src/theme"
import { Wallet } from "./src/utils/wallet/Wallet"
import axios from "axios"
import { ToastContainer } from "react-toastify"
import { UserContextProvider } from "@contexts/user"
import { FeaturesContextProvider } from "@contexts/features"
import { NftContextProvider } from "@contexts/nft"
import App from "./src/components/app"
import { QueryClient, QueryClientProvider } from "react-query"
import "./src/styles.css"
import "react-toastify/dist/ReactToastify.css"
import "photoswipe/dist/default-skin/default-skin.css"
import "photoswipe/dist/photoswipe.css"
const { Buffer } = require("buffer")

window.Buffer = Buffer

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60000,
      cacheTime: 60000,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})
axios.defaults.baseURL = process.env.GATSBY_API_URL

export const wrapRootElement = ({ element }) => (
  <Wallet>
    <QueryClientProvider client={queryClient}>
      <UserContextProvider>
        <FeaturesContextProvider>
          <NftContextProvider>
            <Grommet theme={theme} background="light-1">
              <App>{element}</App>
              <div id="modalContainer" />
            </Grommet>
            <ToastContainer />
          </NftContextProvider>
        </FeaturesContextProvider>
      </UserContextProvider>
    </QueryClientProvider>
  </Wallet>
)
