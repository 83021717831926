// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-become-a-creator-js": () => import("./../../../src/pages/become-a-creator.js" /* webpackChunkName: "component---src-pages-become-a-creator-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-collection-create-js": () => import("./../../../src/pages/collection/create.js" /* webpackChunkName: "component---src-pages-collection-create-js" */),
  "component---src-pages-collections-js": () => import("./../../../src/pages/collections.js" /* webpackChunkName: "component---src-pages-collections-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nft-[address]-js": () => import("./../../../src/pages/nft/[address].js" /* webpackChunkName: "component---src-pages-nft-[address]-js" */),
  "component---src-pages-nft-create-js": () => import("./../../../src/pages/nft/create.js" /* webpackChunkName: "component---src-pages-nft-create-js" */),
  "component---src-pages-nfts-js": () => import("./../../../src/pages/nfts.js" /* webpackChunkName: "component---src-pages-nfts-js" */),
  "component---src-pages-preview-[type]-jsx": () => import("./../../../src/pages/preview/[type].jsx" /* webpackChunkName: "component---src-pages-preview-[type]-jsx" */),
  "component---src-pages-user-[address]-js": () => import("./../../../src/pages/user/[address].js" /* webpackChunkName: "component---src-pages-user-[address]-js" */),
  "component---src-pages-user-edit-js": () => import("./../../../src/pages/user/edit.js" /* webpackChunkName: "component---src-pages-user-edit-js" */),
  "component---src-pages-user-forgot-password-js": () => import("./../../../src/pages/user/forgot-password.js" /* webpackChunkName: "component---src-pages-user-forgot-password-js" */),
  "component---src-pages-user-login-js": () => import("./../../../src/pages/user/login.js" /* webpackChunkName: "component---src-pages-user-login-js" */),
  "component---src-pages-user-register-js": () => import("./../../../src/pages/user/register.js" /* webpackChunkName: "component---src-pages-user-register-js" */),
  "component---src-pages-user-reset-password-js": () => import("./../../../src/pages/user/reset-password.js" /* webpackChunkName: "component---src-pages-user-reset-password-js" */),
  "component---src-templates-collection-collection-template-jsx": () => import("./../../../src/templates/collection/collectionTemplate.jsx" /* webpackChunkName: "component---src-templates-collection-collection-template-jsx" */),
  "component---src-templates-page-template-jsx": () => import("./../../../src/templates/pageTemplate.jsx" /* webpackChunkName: "component---src-templates-page-template-jsx" */)
}

