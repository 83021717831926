import React from "react"
import * as Sentry from "@sentry/browser"
import { useWallet } from "@solana/wallet-adapter-react"
import axios from "axios"

const initialState = {
  // check if the user is fully logged in with both Laravel and wallet
  loggedIn: false,
  // the Laravel Passport token
  token: null,
  // the wallet publick key
  publicKey: null,
  // the amount of lamports in the wallet
  balance: null,

  // the profile data
  data: {
    name: null,
    is_artist: false,
    email: null,
    bio: null,
    avatar_url: null,
    website: null,
    followings: [],
    followers: [],
    likes: [],
    likes_nfts: [],
  },
}

/**
 * List of allowed actions to be applied to the user object
 */
const reducer = (state, action) => {
  let newState = state
  switch (action.type) {
    /**
     * SET_TOKEN is used after the user has logged in succesfuly
     * with Laravel Passport
     */
    case "SET_TOKEN":
      axios.defaults.headers["Authorization"] = "Bearer " + action.token

      newState = {
        ...state,
        loggedIn: action.token !== null && state.publicKey !== null,
        token: action.token,
      }
      break

    /**
     * SET_PUBLIC_KEY is used after the user has logged in succesfuly
     * with his wallet
     */
    case "SET_PUBLIC_KEY":
      axios.defaults.headers["X-Wallet-Address"] = action.publicKey
      Sentry.setUser({ id: action.publicKey })

      newState = {
        ...state,
        loggedIn: state.token !== null && action.publicKey !== null,
        publicKey: action.publicKey,
      }
      break

    /**
     * SET_BALANCE is used to set the nuumber of lamports in the user's wallet
     */
    case "SET_BALANCE":
      newState = {
        ...state,
        balance: action.balance,
      }
      break

    /**
     * UNSET_PUBLIC_KEY is used after the user has logged in succesfuly
     * with his wallet
     */
    case "UNSET_PUBLIC_KEY":
      axios.defaults.headers["X-Wallet-Address"] = null
      Sentry.setUser({ id: undefined })

      newState = {
        ...state,
        loggedIn: false,
        publicKey: null,
      }
      break

    /**
     * UPDATE_PROFILE_DATA is used each time the profile data is fetched
     * or edited
     */
    case "UPDATE_PROFILE_DATA":
      newState = {
        ...state,
        data: {
          ...state.data,
          name: action.name || "",
          is_artist: action.is_artist || false,
          email: action.email || "",
          bio: action.bio || "",
          avatar_url: action.avatar_url || "",
          website: action.website || "",
          followings: action.followings || [],
          followers: action.followers || [],
          likes: action.favorite_collections || [],
          likes_nfts: action.favorite_tokens || [],
        },
      }
      newState.data.likes = newState.data.likes.map(c => c.address)
      newState.data.likes_nfts = newState.data.likes_nfts.map(c => c.address)
      Sentry.setUser({ username: action.name })
      break

    case "FOLLOW_USER":
      newState = {
        ...state,
      }
      newState.data.followings.push(action.profile)
      break

    case "UNFOLLOW_USER":
      newState = {
        ...state,
        data: {
          ...state.data,
          followings: state.data.followings.filter(
            profile => profile.address !== action.address
          ),
        },
      }
      break

    case "LIKE_COLLECTION":
      newState = {
        ...state,
      }
      newState.data.likes.push(action.address)
      break

    case "UNLIKE_COLLECTION":
      newState = {
        ...state,
        data: {
          ...state.data,
          likes: state.data.likes.filter(address => address !== action.address),
        },
      }
      break

    case "LIKE_NFT":
      newState = {
        ...state,
      }
      newState.data.likes_nfts.push(action.address)
      break

    case "UNLIKE_NFT":
      newState = {
        ...state,
        data: {
          ...state.data,
          likes_nfts: state.data.likes_nfts.filter(
            address => address !== action.address
          ),
        },
      }
      break

    /**
     * LOGOUT is used after the user has asked for logout
     * or the user needs to be disconnected for security reasons
     */
    case "LOGOUT":
      axios.defaults.headers["Authorization"] = null
      axios.defaults.headers["X-Wallet-Address"] = null
      Sentry.configureScope(scope => scope.setUser(null))

      if (typeof window !== "undefined") {
        localStorage.removeItem("n4a_user")
      }

      // Note that we return here becase we dont want to set the localstorage
      // again
      return initialState

    default:
      throw new Error()
  }

  if (typeof window !== "undefined") {
    localStorage.setItem("n4a_user", JSON.stringify(newState))
  }
  return newState
}

const UserContext = React.createContext()

const UserContextProvider = ({ children }) => {
  const { publicKey } = useWallet()
  const base58 = React.useMemo(() => publicKey?.toBase58(), [publicKey])

  const userReducer = React.useReducer(
    reducer,
    initialState,
    /**
     * reducer init function
     * We use it to check if the user is loggedin with localstorage
     */ () => {
      let storedUser = null
      if (typeof window !== "undefined") {
        storedUser = localStorage.getItem("n4a_user")
      }

      if (!storedUser) {
        return initialState
      }

      storedUser = JSON.parse(storedUser)

      if (storedUser.token) {
        axios.defaults.headers["Authorization"] = "Bearer " + storedUser.token
        // this is a little bit useless as the UserConnect component will start
        // to rewrite it beginning with a null value.
        axios.defaults.headers["X-Wallet-Address"] = storedUser.publicKey

        return {
          ...initialState,
          loggedIn: base58 && storedUser.token,
          token: storedUser.token,
          publicKey: storedUser.publicKey,
          data: { ...initialState.data, ...storedUser.data },
        }
      } else {
        return initialState
      }
    }
  )
  return (
    <UserContext.Provider value={userReducer}>{children}</UserContext.Provider>
  )
}

const useUser = () => React.useContext(UserContext)

export { useUser, UserContext, UserContextProvider }
