import { LAMPORT_MULTIPLIER } from "../utils/oyster/common/src/index.tsx"
import axios from "axios"
import * as Sentry from "@sentry/browser"
import { Program, Provider, web3, BN } from "@project-serum/anchor"
import * as spl from "@solana/spl-token"
const { Connection, programs } = require("@metaplex/js")
const idl = require("../idl.json")

const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID = new web3.PublicKey(
  "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
)

const PLATFORM_ACCOUNT_ID = new web3.PublicKey(
  "C6r3vSgFxGs2826D1hWxdVin3dA39S2SYfsU7L84xSZJ"
)

function getProgram(connection, wallet) {
  const provider = new Provider(
    connection,
    wallet,
    "confirmed" // commitment
  )

  const program = new Program(idl, idl.metadata.address, provider)
  return program
}

export const getAllListedNfts = async wallet => {
  const connection = new Connection(
    process.env.GATSBY_SOLANA_NETWORK,
    "confirmed"
  )
  const program = getProgram(connection, wallet)
  const dataAccounts = await program.account.listingState.all()

  return dataAccounts.map(dataAccount => ({
    dataAccount: dataAccount.publicKey,
    nft: dataAccount.account.nft.toBase58(),
    seller: dataAccount.account.seller.toBase58(),
    price: dataAccount.account.price.toNumber(),
    listed_at: dataAccount.account.listedAt.toNumber(),
  }))
}

export const listNft = async (wallet, address, price) => {
  if (!Number.isSafeInteger(price)) {
    throw new Error("price is not valid")
  }

  const connection = new Connection(
    process.env.GATSBY_SOLANA_NETWORK,
    "confirmed"
  )
  const program = getProgram(connection, wallet)

  const dataAccount = web3.Keypair.generate()
  const token = new web3.PublicKey(address)

  // Get the tokenAccount currently holding the NFT
  const tokenAccounts = await connection.getTokenAccountsByOwner(
    wallet.publicKey,
    {
      mint: token,
    }
  )

  const mintAssociatedAccount = tokenAccounts.value[0]

  // derive a PDA for the listing account
  const [listingAccount, listingAccountBump] =
    await web3.PublicKey.findProgramAddress(
      [token.toBuffer()],
      program.programId
    )

  try {
    await program.rpc.initialize(new BN(listingAccountBump), new BN(price), {
      accounts: {
        // 1. Account that will contain data,
        listingData: dataAccount.publicKey,
        // 2. the person who wants to list the NFT,
        seller: wallet.publicKey,
        // 3. PDA account,
        listingAccount: listingAccount,
        // 4. the NFT to list,
        nft: token,
        // 5. the token account belonging to the seller
        nftTokenAccount: mintAssociatedAccount.pubkey,
        // 6. the Token Program,
        tokenProgram: spl.TOKEN_PROGRAM_ID,
        // 7. the System Program,
        systemProgram: web3.SystemProgram.programId,
        // 8 the Rent Sysvar,
        rent: web3.SYSVAR_RENT_PUBKEY,
      },
      signers: [dataAccount],
    })
  } catch (err) {
    Sentry.captureException(err, {
      tags: {
        action: "solana.n4a.initialize",
        price: price,
        nft: address,
      },
    })

    throw err
  }
}

/**
 * unListNft allows a user to withdraw a NFT
 */
export const unListNft = async (wallet, address, dataAccount) => {
  const connection = new Connection(
    process.env.GATSBY_SOLANA_NETWORK,
    "confirmed"
  )
  const program = getProgram(connection, wallet)

  const token = new web3.PublicKey(address)

  // Get the tokenAccount currently holding the NFT
  const tokenAccounts = await connection.getTokenAccountsByOwner(
    wallet.publicKey,
    {
      mint: token,
    }
  )

  const mintAssociatedAccount = tokenAccounts.value[0]

  // derive a PDA for the listing account
  const [listingAccount] = await web3.PublicKey.findProgramAddress(
    [token.toBuffer()],
    program.programId
  )

  try {
    await program.rpc.unlist({
      accounts: {
        // 1. Account that contains listing data,
        listingData: dataAccount,
        // 2. the person who listed the NFT,
        seller: wallet.publicKey,
        // 3. the token account belonging to the seller
        nftTokenAccount: mintAssociatedAccount.pubkey,
        // 4. PDA account currently holding the NFT,
        listingAccount: listingAccount,
        // 5. the Token Program,
        tokenProgram: spl.TOKEN_PROGRAM_ID,
      },
    })
  } catch (err) {
    console.log(err)
    Sentry.captureException(err, {
      tags: {
        action: "solana.n4a.unlist",
        nft: address,
      },
    })
    throw err
  }
}

/**
 * buyNft allow the current use to by the NFT
 */
export const buyNft = async (wallet, seller, address, dataAccount, price) => {
  const connection = new Connection(
    process.env.GATSBY_SOLANA_NETWORK,
    "confirmed"
  )

  const program = getProgram(connection, wallet)

  const token = new web3.PublicKey(address)

  // derive a PDA for the listing account
  const [listingAccount] = await web3.PublicKey.findProgramAddress(
    [token.toBuffer()],
    program.programId
  )

  const buyerAssociatedTokenAccount = (
    await web3.PublicKey.findProgramAddress(
      [
        wallet.publicKey.toBuffer(),
        spl.TOKEN_PROGRAM_ID.toBuffer(),
        token.toBuffer(),
      ],
      SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID
    )
  )[0]

  let tx
  const mintPda = await programs.metadata.Metadata.getPDA(address)
  const metadata = await programs.metadata.Metadata.load(connection, mintPda)
  const data = metadata?.data?.data

  try {
    tx = await program.rpc.buy({
      accounts: {
        // 1. Account that contains listing data,
        listingData: dataAccount,
        // 2. the person who listed the NFT,
        seller: new web3.PublicKey(seller),
        // 3. the person is buying the NFT,
        buyer: wallet.publicKey,
        // 4. the mintPda
        mintPda: mintPda,
        // 5. the NFT to list,
        nft: token,
        // 6. the token account belonging to the buyer
        nftTokenAccount: buyerAssociatedTokenAccount,
        // 7. PDA account currently holding the NFT,
        listingAccount: listingAccount,
        // 8. platformAccount
        platformAccount: PLATFORM_ACCOUNT_ID,
        // 9. the Token Program,
        tokenProgram: spl.TOKEN_PROGRAM_ID,
        // 10. the AssociatedToken Program,
        associatedTokenProgram: SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID,
        // 11. the System Program,
        systemProgram: web3.SystemProgram.programId,
        // 12 the Rent Sysvar,
        rent: web3.SYSVAR_RENT_PUBKEY,
      },
      remainingAccounts: data.creators.map(creator => ({
        pubkey: new web3.PublicKey(creator.address),
        isWritable: true,
        isSigner: false,
      })),
    })
  } catch (err) {
    Sentry.captureException(err, {
      tags: {
        action: "solana.n4a.buy",
        nft: address,
      },
    })
    throw err
  }

  try {
    await axios.post(`/api/token/${address}/sale`, {
      transaction_hash: tx,
      seller_address: seller,
      price: price / LAMPORT_MULTIPLIER,
    })
  } catch (err) {
    Sentry.captureException(err, {
      tags: {
        action: "laravel.buy",
        tx,
        nft: address,
      },
    })

    // we dont throw the error back to the user as it might
    // scare them
  }
}

/**
 * getNftByAddress Retrieves a NFT
 */
export const getNftByAddress = address => {
  return axios.get(`/api/token/${address}`).then(response => response.data.data)
}

/**
 * getNftListByAddress retrieves a list of NFTs
 */
export const getNftListByAddress = async addresses => {
  if (!addresses || addresses.length === 0) {
    return []
  }

  const response = await axios.post(`/api/token/by-address`, { addresses })

  const raw = response.data.data
  const keys = Object.keys(raw)
  const nfts = Object.values(raw)

  for (let i = 0; i < nfts.length; i++) {
    if (nfts[i] === null) {
      nfts[i] = {
        ...(await getNftByAddressFromSolana(keys[i])),
      }
    }
  }

  return nfts.map(nft => ({
    ...nft,
    address: nft.address || nft.mint,
    // this image assignement should be remove when task PN1-T174 is closed
    image: nft.image || nft.image_url,
  }))
}

/**
 * unlistNftByAddress Unlists a NFT
 */
export const unlistNftByAddress = address => {
  return axios
    .delete(`/api/token/${address}`)
    .then(response => response.data.data)
}

/**
 * getNftByAddressFromSolana Retrieves a NFT from the Solana Blockchain
 */
export const getNftByAddressFromSolana = async address => {
  const connection = new Connection(process.env.GATSBY_SOLANA_NETWORK)
  console.info("getNftByAddressFromSolana is starting")

  // 1. find PDA from NFT's Mint address

  const pda = await programs.metadata.Metadata.getPDA(address)

  // 2. get metadata attached to the PDA
  const metadata = await programs.metadata.Metadata.load(connection, pda)
  const data = metadata?.data?.data

  // 3. get the JSON attached to the NFT (probably on arweave)
  const response = await fetch(data.uri)

  const json = await response.json()

  return { ...json, address }
}

/**
 * Get last sold tokens
 */
export const getLastSoldTokens = () => {
  return axios({
    method: "get",
    url: `/api/last-sold-tokens`,
  }).then(response => {
    return response.data.data
  })
}

/**
 * Like a NFT
 */
export const likeNft = address => {
  return axios({
    method: "post",
    url: `/api/token/${address}/like`,
  }).then(response => {
    return response.data.data
  })
}

/**
 * Unlike a NFT
 */
export const unlikeNft = address => {
  return axios({
    method: "delete",
    url: `/api/token/${address}/like`,
  }).then(response => {
    return response.data.data
  })
}
