import React from "react"
import { useUser } from "@contexts/user"
import { useFeatures } from "@contexts/features"
import { useNfts } from "@contexts/nft"
import { getAllListedNfts } from "@actions/nft"
import { useWallet } from "@solana/wallet-adapter-react"
import axios from "axios"

const App = ({ children }) => {
  const [user] = useUser()
  const [, dispatch] = useNfts()
  const [, setFeatures] = useFeatures()
  const wallet = useWallet()

  React.useEffect(() => {
    getAllListedNfts(wallet).then(nfts => {
      dispatch({ type: "UPDATE", nfts })
    })
  }, [wallet, dispatch])

  React.useEffect(() => {
    axios.get("/api/feature").then(f => {
      setFeatures(f.data.data)
    })
  }, [setFeatures, user.loggedIn])

  return children
}

export default App
