import React, { useMemo } from "react"
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react"
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base"
import { clusterApiUrl } from "@solana/web3.js"
import { getPhantomWallet } from "./getPhantomWallet"
import { getSolflareWallet } from "./getSolflareWallet"
import { getSolflareWebWallet } from "./getSolflareWalletWeb"

export const Wallet = ({ children }) => {
  // Can be set to 'devnet', 'testnet', or 'mainnet-beta'
  const network = WalletAdapterNetwork.Devnet

  // You can also provide a custom RPC endpoint
  const endpoint = useMemo(() => clusterApiUrl(network), [network])

  const wallets = useMemo(() => {
    const wallets = []
    if (typeof window !== "undefined") {
      // desktop only  wallets
      if (
        (window.innerWidth >= 800 && window.innerHeight >= 600) ||
        "phantom" in window
      ) {
        wallets.push(getPhantomWallet())
      }

      if ("solflare" in window) {
        // we have the app installed
        wallets.push(getSolflareWallet())
      } else {
        wallets.push(getSolflareWebWallet())
      }
    }

    return wallets
  }, [])

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        {children}
      </WalletProvider>
    </ConnectionProvider>
  )
}
