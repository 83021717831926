import { grommet } from "grommet"
import { deepMerge } from "grommet/utils"
import { css } from "styled-components"
//import CarouselArrow from "@bw/bits"

const StyleTextInput = css`
  background: rgba(255, 255, 255, 0.9);
  opacity: 0.75;
  box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.3),
    -1px -1px 2px rgba(205, 219, 236, 0.5),
    inset -5px 5px 10px rgba(168, 181, 214, 0.2),
    inset 5px 5px 13px rgba(220, 231, 253, 0.7);
  backdrop-filter: blur(40px);
  border-radius: 4px;
  font-size: 12px;
  padding-left: 15px;
  line-height: 12px;
  letter-spacing: 0.055em;
  border: none;
  height: 35px;
`

const theme = deepMerge(grommet, {
  global: {
    colors: {
      brand: "#04062C",
      text: "#04062C",
      primary: "#6080E9",
      "light-1": "#F3F4FA",
    },
    breakpoints: {
      xsmall: { value: 320 },
      small: { value: 520 },
      medium: { value: 768 },
      large: { value: 1024 },
      xlarge: { value: 1200 },
    },
    font: {
      family: "'Lato', sans-serif;",
    },
    form: {
      textInput: StyleTextInput,
    },
    carousel: {
      icons: {
        // next: <CarouselArrow />,
        // previous: <CarouselArrow />,
        color: "#04062C",
      },
    },
    disabled: {
      icons: {
        color: "#D2D3E8",
      },
    },
  },
})

export default theme

export const breakpoints = {
  xsmall: 320,
  small: 520,
  medium: 768,
  large: 1024,
  xlarge: 1200,
}

export const mediaQuery = key => {
  const width =
    typeof theme.global.breakpoints[key] !== "undefined"
      ? theme.global.breakpoints[key].value + "px"
      : key
  return style => `@media (min-width: ${width}) { ${style} }`
}
