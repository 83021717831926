import React from "react"

const initialState = {
  loading: true, // initial load happened ?
  listed: [],
}

/**
 * List of allowed actions to be applied to the nft listing object
 */
const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE":
      return {
        ...state,
        loading: false,
        listed: action.nfts,
      }

    case "UNLIST":
      return {
        ...state,
        listed: state.listed.filter(nft => nft.nft !== action.address),
      }

    case "LIST":
      return {
        ...state,
        listed: [...state.listed, action.nft],
      }

    default:
      throw new Error()
  }
}

const NftContext = React.createContext()

const NftContextProvider = ({ children }) => {
  const nftReducer = React.useReducer(reducer, initialState)

  return (
    <NftContext.Provider value={nftReducer}>{children}</NftContext.Provider>
  )
}

const useNfts = () => React.useContext(NftContext)

export { useNfts, NftContext, NftContextProvider }
